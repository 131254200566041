a{
    text-decoration: none;
    color: #333333;
}

.padding10{
    /* padding: 2% 5%; */
    padding: 5%;
}

.banner {
    border-radius: 35px;
    background-color: rgba(255,255,255,0.3);
    /* box-shadow: rgba(0, 0, 0, 0.15) 2.95px 4.95px 2.6px; */
    box-shadow: rgba(0, 0, 0, 0.35) 3px 5px 15px
}

.banner > .bannerLeft {
    display: flex;
}

.banner > .bannerLeft > .bannerContent {
    align-self: center;
}

.banner > .bannerLeft > .bannerContent .bannerText {
    text-transform: uppercase;
}

.banner img {
    height: 100%;
}

.transparentButtons {
    background: #0077B5;
    color: #fff;
    display:inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    transition: 0.2s box-shadow ease-in-out, 0.2s background-color ease-in-out;
    white-space: nowrap;
    padding: 1em 3em;
    
}

.transparentButtons:hover {
    background: darken(#0077B5, 5%);
    box-shadow: inset 0 0 6px #333;
  }

.container-lg .myclass a{
    font-size: 1.4rem;

}

.container-lg .myclass a:hover{
    font-size: 1.35;
    font-weight: 700;
    color: rgb(1, 115, 115);
}

@media (min-width : 320px) and (max-width: 640px)  {
    .banner > .bannerLeft > .bannerContent .bannerText > div{
        width: 100%;
    }
    .banner > .bannerLeft > .bannerContent .bannerText h1{
        text-transform: uppercase;
        text-align: center;
    }

    .banner > .bannerLeft > .bannerContent .bannerText h3{
        text-transform: uppercase;
        text-align: center;
    }

    .banner > .bannerLeft > .bannerContent .bannerText {
        padding-bottom: 0px !important;
    }

    .padding10{
        padding: 3% 3% 10% 5%;
    }    
    
}
