
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

/* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-next, .swiper-button-prev{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: black;
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: initial;
}

.morevideos{
    margin: auto;
    /* background-color: transparent; */
    cursor: pointer;
    font-size: 6.5rem;
}

.achivediv{
    display: grid;
    grid-template-rows: 2fr 1fr 1fr;
}