.textLeft{
    text-align: left;
}

.mapDivTag{
    border: 10px solid white;
    background-color: white;
}
.fotmob{
    display: grid;
    grid-template-columns: 0.1fr auto;
    text-align: left;
    column-gap: 10px;
}
.fotmob img{
    width: 25px;
}

.cpufooter{
    color: #b20f49  !important;
}

