.App {
  text-align: center;
  background: url(../src/assets/imgs/homebg.png)  no-repeat;
  background-size: cover;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p{
  text-align: justify;

}
.bgtexter{
  background-color: #61DBBF;
  font-weight: 900;
  font-size: 1rem;
  border-radius: 10px;
}

h2{
  /* color: #e6cea6; */
  color: #b20f49  !important;
}
h3{
  color: #5c7476;
}
h4{
  color: #126a6d;
}
p{
  font-size: 1.3rem;
}